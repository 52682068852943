import { defineComponent, onMounted, ref } from '@vue/composition-api';
import { countdownTimerHumanizedDuration } from '@/util/dates';
export default defineComponent({
    name: 'DeadlineCountdownTimer',
    props: {
        deadline: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const remainingTime = ref('');
        const updateDeadlineTime = () => {
            const deadline = props.deadline;
            const currentTime = globalThis.$moment().unix();
            const diffTime = deadline - currentTime;
            const duration = globalThis.$moment.duration(diffTime * 1000, 'milliseconds');
            remainingTime.value =
                diffTime > 0 ? countdownTimerHumanizedDuration(duration) : '0';
        };
        onMounted(() => {
            setInterval(() => updateDeadlineTime(), 1000);
        });
        return { remainingTime };
    }
});
