export default function useStructuredData() {
    /**
     * Removes previously injected structured data from the head tag
     */
    const _removePrevInjected = () => {
        const allScripts = document.querySelectorAll('script');
        allScripts.forEach((el) => {
            if (el.id === 'structuredData') {
                // Removing previously injected script to avoid duplication
                document.head.removeChild(el);
            }
        });
    };
    /**
     * Ejects structured data from the head tag
     */
    const ejectStructuredData = () => {
        _removePrevInjected();
    };
    /**
     * Injects structured data into the head tag
     * @param structuredData
     */
    const injectStructuredData = (structuredData) => {
        if (structuredData) {
            _removePrevInjected(); // check prev injection and remove if needed
            const dataInjector = document.createElement('script');
            dataInjector.setAttribute('type', 'application/ld+json');
            dataInjector.setAttribute('id', 'structuredData');
            // Adding structured data into script tag
            dataInjector.appendChild(document.createTextNode(JSON.stringify(structuredData)));
            // Injection
            document.head.appendChild(dataInjector);
        }
    };
    return { injectStructuredData, ejectStructuredData };
}
