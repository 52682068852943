import { OrderItemType } from '@/generated-types/graphql.types';
import ListDropdown from '@/uikit/ListDropdown.vue';
import ListItem from '@/uikit/ListItem.vue';
import { computed, defineComponent } from '@vue/composition-api';
import { dateUnixTZ } from '@/util/dates';
import VATBreakdown from '@/Space/components/VATBreakdown.vue';
import { useBookingTimeSlots } from '@/util/dates';
import { SpaceOrderItemType } from '@/generated-types/graphql.types';
import { groupBy } from 'lodash';
import Popover from '@/components/Popover.vue';
import EventBus from '@/shared/services/eventBus';
export default defineComponent({
    components: {
        ListDropdown,
        ListItem,
        VATBreakdown,
        Popover
    },
    props: {
        bookingSummary: {
            type: Object,
            default: null
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const spacePrice = computed(() => props.bookingSummary?.order.order_items.find(item => item.order_item_type === OrderItemType.Space)?.price);
        const services = computed(() => {
            const allServices = props.bookingSummary?.order.order_items.filter(item => item.order_item_type === OrderItemType.Service);
            // all services are grouped by the service type
            return (allServices && groupBy(allServices, service => service?.service_type));
        });
        const bookingDateTitle = computed(() => {
            const bookingDuration = (props.bookingSummary.slot_end - props.bookingSummary.slot_start) /
                3600;
            const bookingDay = dateUnixTZ(props.bookingSummary.slot_start, globalThis.$timezone);
            return bookingDuration
                ? bookingDay.format('D MMM YYYY')
                : root.$i18n.t('booking_summary.select_day');
        });
        const bookingRange = computed(() => {
            if (!props.bookingSummary.slot_start ||
                !props.bookingSummary.slot_end ||
                props.bookingSummary.slot_start === props.bookingSummary.slot_end)
                return '';
            return useBookingTimeSlots(props.bookingSummary, true);
        });
        const configuration = computed(() => props.bookingSummary.order.order_items.find(item => item.order_item_type === OrderItemType.Configuration));
        const removeService = (item) => {
            if (!props.editable) {
                return;
            }
            emit('removeService', item);
        };
        const thumbnail = computed(() => `${props.bookingSummary.space?.space_images?.[0]?.image_url}?tr:w-72`);
        const onDateSectionClick = () => {
            emit('close');
            if (root.$mq.phone) {
                // Emit event to the WeekView.vue component
                EventBus.$emit('dateSectionClicked');
            }
        };
        return {
            bookingDateTitle,
            bookingRange,
            configuration,
            services,
            spacePrice,
            thumbnail,
            SpaceOrderItemType,
            onDateSectionClick,
            removeService
        };
    }
});
