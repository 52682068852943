import { defineComponent, onUnmounted, watch } from '@vue/composition-api';
import { usePaymentLink } from '@/util/hooks';
import { BookingRoutes } from '@/Booking/booking.const';
import { getBookingTimeline } from '@/Booking/utils/bookings';
import DeadlineCountdownTimer from '@/components/DeadlineCountdownTimer.vue';
import useStructuredData from "@/shared/composables/useStructuredData";
import EventBus from "@/shared/services/eventBus";
export default defineComponent({
    components: {
        DeadlineCountdownTimer
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const { pay, loading } = usePaymentLink(props.booking.booking_id, `booking${BookingRoutes.PAYMENT}/${props.booking.booking_hash}/${BookingRoutes.SUCCESS}`);
        const timeline = getBookingTimeline(props.booking, false);
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        EventBus.$on('onBreadcrumbsReady', data => {
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        watch(() => [timeline, props.booking], ([currentTimeline, booking]) => {
            if ((currentTimeline && !currentTimeline.PAY) ||
                (booking && !booking.order?.payer?.account_id)) {
                globalThis.$router.replace(`${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/${props.booking.booking_hash}`);
            }
        }, { immediate: true });
        return {
            loading,
            pay
        };
    }
});
