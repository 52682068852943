var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bookingSummary)?_c('section',{staticClass:"booking-summary"},[(_vm.bookingSummary.space)?_c('div',{staticClass:"booking-summary-space"},[(_vm.thumbnail)?_c('img',{attrs:{"src":_vm.thumbnail,"alt":"Space thumbnail","loading":"lazy"}}):_vm._e(),_c('div',{staticClass:"booking-summary-title"},[_c('strong',{staticClass:"booking-summary-title-ref"},[_vm._v(_vm._s(_vm.bookingSummary.space.internal_name)+" ")])])]):_vm._e(),(_vm.bookingSummary)?_c('ul',{staticClass:"booking-summary-list"},[(_vm.bookingSummary.space)?_c('ListItem',{attrs:{"title":_vm.bookingDateTitle,"icon-class":"fa-solid fa-calendar-days fa-lg","description":_vm.bookingRange.value,"right-label":_vm._f("currency")(_vm.spacePrice),"has-price-not-set":!_vm.bookingRange},on:{"click":_vm.onDateSectionClick}}):_vm._e(),(_vm.bookingSummary.attendees_selected_count)?_c('ListItem',{attrs:{"icon-class":"fa-solid fa-person fa-lg","title":_vm.$tc(
          'booking_summary.attendees',
          _vm.bookingSummary.attendees_selected_count
        )}}):_vm._e(),(_vm.configuration)?_c('ListItem',{attrs:{"icon-class":"fa-solid fa-chair fa-lg","right-label":_vm._f("currency")(_vm.configuration.price),"has-price-not-set":!_vm.bookingRange,"title":_vm.configuration.configuration_type
          ? _vm.$t(
              ("booking_summary.configurations." + (_vm.configuration.configuration_type.toLowerCase()) + ".title")
            )
          : ''}}):_vm._e(),_vm._l((_vm.services),function(items,serviceType){return _c('ListDropdown',{key:serviceType,attrs:{"expanded-mode":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ListItem',{attrs:{"icon-class":"fa-solid fa-list fa-lg","right-label":_vm._f("currency")(Object.values(items).reduce(function (acc, item) { return item.price + acc; }, 0)),"title":_vm.$tc(
              ("booking_summary." + (serviceType.toLowerCase()) + ".booker_title"),
              Object.keys(items).length
            ),"expandable":""}})]},proxy:true},{key:"items",fn:function(){return _vm._l((items),function(item){return _c('ListItem',{key:item.name,attrs:{"icon-class":"fa-solid fa-trash fa-xs","right-label":_vm._f("currency")(item.price),"title":_vm.$tc(
              ("booking_summary." + (item.service_type.toLowerCase()) + "." + (item.name) + ".title"),
              item.quantity
            )},on:{"icon-click":function($event){return _vm.removeService(item)}}})})},proxy:true}],null,true)})}),(
        _vm.bookingSummary.is_discount_applied || _vm.bookingSummary.is_coupon_applied
      )?_c('ListItem',{staticClass:"booking-summary-list__discount-total",attrs:{"title":_vm.$tc(
          'customer.space.book_preview.discount_level',
          _vm.bookingSummary.discount_rate
        ),"right-label":_vm._f("currency")(_vm.bookingSummary.order.discount_total)}}):_vm._e(),_c('ListItem',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_c('div',{staticClass:"booking-summary-list__total"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.price.title')))]),_c('span',[_vm._v(" · "+_vm._s(_vm.$t('booking_summary.summary.price.description')))])]),(_vm.bookingRange)?_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.bookingSummary.total_vat_excl)))]):_c('div',[_c('Popover',[_c('div',[_c('i',{staticClass:"fa-solid fa-circle-question fa-sm"})]),_c('div',{staticClass:"selection-required-popover",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t('booking_summary.empty_price_popover'))+" ")])])],1)])]},proxy:true}],null,false,1332245480)}),_c('ListItem',{attrs:{"border":false},scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_c('div',{staticClass:"booking-summary-list__total"},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.$t('booking_summary.summary.total.title')))]),_c('span',[_vm._v(" · "+_vm._s(_vm.$t('booking_summary.summary.total.description')))])]),(_vm.bookingRange)?_c('div',[_c('VATBreakdown',{attrs:{"vat21":_vm.bookingSummary.vat_21,"vat9":_vm.bookingSummary.vat_9}})],1):_vm._e(),(_vm.bookingRange)?_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.bookingSummary.total)))]):_c('div',[_c('Popover',[_c('div',[_c('i',{staticClass:"fa-solid fa-circle-question fa-sm"})]),_c('div',{staticClass:"selection-required-popover",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.$t('booking_summary.empty_price_popover'))+" ")])])],1)])]},proxy:true}],null,false,1965168164)})],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }