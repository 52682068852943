import { defineComponent, ref, computed, onUnmounted } from '@vue/composition-api';
import { OrderStateType } from '@/generated-types/graphql.types';
import BookingPaymentStatus from '@/Booking/components/BookingPaymentStatus.vue';
import { usePaymentLink } from '@/util/hooks';
import { BookingRoutes } from '@/Booking/booking.const';
import useUserRoles from '@/shared/composables/useUserRoles';
import useStructuredData from "@/shared/composables/useStructuredData";
import EventBus from "@/shared/services/eventBus";
export default defineComponent({
    components: {
        BookingPaymentStatus
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props, _) {
        const { pay, loading } = usePaymentLink(props.booking.booking_id, `booking${BookingRoutes.PAYMENT}/${props.booking.booking_hash}/${BookingRoutes.SUCCESS}`);
        const status = ref(null);
        const roles = useUserRoles();
        const isPaymentSuccessful = computed(() => {
            return props.booking.order.order_state === OrderStateType.Paid;
        });
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        EventBus.$on('onBreadcrumbsReady', data => {
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            isPaymentSuccessful,
            loading,
            roles,
            status,
            OrderStateType,
            pay
        };
    }
});
