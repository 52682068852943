import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api';
import { GetBookingByHashDocument, useAddBookingPayerMutation } from '@/generated-types/graphql.types';
import PhoneNumber from '@/uikit/PhoneNumber/PhoneNumber.vue';
import Tip from '@/uikit/Tip.vue';
import { validationMixin } from 'vuelidate';
import { required, email, requiredIf } from 'vuelidate/lib/validators';
import { serverValidationMixin, useValidation } from '@/util/validation';
import { BookingRoutes } from '@/Booking/booking.const';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
import { getBookingTimeline } from '@/Booking/utils/bookings';
import { getOperationName } from 'apollo-link';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import ListItem from '@/uikit/ListItem.vue';
import useStructuredData from "@/shared/composables/useStructuredData";
import EventBus from "@/shared/services/eventBus";
export default defineComponent({
    components: {
        ListItem,
        PhoneNumber,
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        payer: {
            full_name: {
                required
            },
            email: {
                required,
                email
            },
            isOrganisation: {},
            organisation: {
                requiredIf: requiredIf(function () {
                    return this.payer.isOrganisation;
                })
            },
            address: {
                requiredIf: requiredIf(function () {
                    return this.payer.isOrganisation;
                })
            },
            terms: {
                checked: value => value === true
            }
        }
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props) {
        const payer = ref({
            full_name: '',
            email: '',
            address: '',
            organisation: '',
            isOrganisation: false,
            terms: false
        });
        const { errors, validate, serverErrors } = useValidation();
        const { mutate } = useAddBookingPayerMutation({});
        const timeline = computed(() => getBookingTimeline(props.booking, false));
        const isPhoneShouldValidating = ref(false);
        const isPhoneValid = ref(false);
        const formPhoneNumber = ref('');
        const phoneValidated = (val, phone, form) => {
            isPhoneShouldValidating.value = false;
            isPhoneValid.value = val;
            if (val)
                submitPayer(form, phone);
        };
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        EventBus.$on('onBreadcrumbsReady', data => {
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        const submitPayer = async (form, phone) => {
            isPhoneShouldValidating.value = true;
            try {
                const valid = validate(form);
                if (!valid || !isPhoneValid.value) {
                    return;
                }
                const { full_name, email, organisation, address } = form.$model;
                const input = {
                    full_name,
                    email,
                    organisation: organisation || undefined,
                    address: address || undefined,
                    phone_number: phone,
                    booking_id: props.booking.booking_id
                };
                await mutate({ input }, {
                    awaitRefetchQueries: true,
                    refetchQueries: [getOperationName(GetBookingByHashDocument)]
                }).then(() => {
                    globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKING}${BookingRoutes.PAYMENT}/${props.booking.booking_hash}/${BookingRoutes.PAY}`));
                });
            }
            catch (errors) {
                validate(form, errors);
            }
        };
        return {
            errors,
            formPhoneNumber,
            isPhoneShouldValidating,
            payer,
            serverErrors,
            timeline,
            HelpdeskRoutes,
            phoneValidated,
            submitPayer,
            useGetLocalizedPath
        };
    }
});
