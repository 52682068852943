import { computed, defineComponent, reactive } from '@vue/composition-api';
import { OrderItemType, useGetBookingByHashQuery } from '@/generated-types/graphql.types';
import BookingSummary from '@/Booking/components/BookingSummary.vue';
import BookingStatus from '@/Booking/components/BookingStatus.vue';
import Card from '@/uikit/Card.vue';
import Collapse from '@/uikit/Collapse.vue';
export default defineComponent({
    components: {
        BookingSummary,
        BookingStatus,
        Card,
        Collapse
    },
    setup(_, context) {
        const { root } = context;
        const { result, onResult: onGetBookingResult } = useGetBookingByHashQuery(() => ({
            hash: root.$route.params.hash
        }));
        const bookingSummary = reactive({
            space: {},
            attendees: null,
            slot_start: null,
            slot_end: null,
            start_time: null,
            end_time: null,
            is_discount_applied: false,
            is_coupon_applied: false,
            discount_rate: null,
            order: {
                discount_total: null,
                order_items: [],
                order_total: null,
                order_total_vat_excl: null
            },
            vat_21: null,
            vat_9: null,
            total_vat_excl: null,
            total: null
        });
        onGetBookingResult(data => {
            const booking = data?.data?.specific_booking_by_hash;
            const vat9items = booking?.order?.order_items?.filter(item => item.vat_rate === 9);
            const vat9total = vat9items?.reduce((acc, item) => item.vat_rate === 9
                ? (item.order_item_price * item.vat_rate) / 100 + acc
                : 0, 0);
            bookingSummary.attendees_selected_count = booking.attendees;
            bookingSummary.is_discount_applied = booking?.is_discount_applied;
            bookingSummary.is_coupon_applied = !!booking?.booking_meta?.coupon;
            bookingSummary.slot_start = booking?.slot_start;
            bookingSummary.slot_end = booking?.slot_end;
            bookingSummary.start_time = booking?.start_time;
            bookingSummary.end_time = booking?.end_time;
            bookingSummary.space = booking?.space;
            bookingSummary.discount_rate =
                booking?.booking_meta?.coupon?.discount_percentage ||
                    booking?.space?.discount_percentage;
            bookingSummary.total = booking?.order.order_total;
            bookingSummary.total_vat_excl = booking?.order.order_total_vat_excl;
            bookingSummary.vat_9 = vat9total;
            bookingSummary.vat_21 = vat9total
                ? booking?.order.vat_total - vat9total
                : booking?.order.vat_total;
            bookingSummary.order.discount_total = booking?.order.discount_total;
            bookingSummary.order.order_total = booking?.order.order_total;
            bookingSummary.order.order_total_vat_excl =
                booking?.order.order_total_vat_excl;
            bookingSummary.order.order_items = booking?.order.order_items?.map(item => {
                return {
                    quantity: item.quantity,
                    order_item_type: item.order_item_type,
                    reference_id: item.reference_id,
                    price: Number((item.order_item_price + item.service_fee).toFixed(2)),
                    service_type: item.service_type,
                    name: item.service_name,
                    configuration_type: booking?.space?.space_configurations?.find(configuration => configuration?.configuration_id === item.reference_id &&
                        item.order_item_type === OrderItemType.Configuration)?.configuration_type
                };
            });
        });
        return {
            booking: computed(() => result.value?.specific_booking_by_hash),
            bookingSummary
        };
    }
});
